export interface IOrder {
    applied_coupon: ICoupon|null,
    price_detail: IPriceDetail,
    lines: Array<ILine>,
    version: number,
    organization_mailing_list_text: string|null,
    organization_name: string|null
}

export interface ISettlement {
    price_detail: IPriceDetail,
    company: ICompany,
    lines: Array<ILine>,
    payment_methods: Array<IPaymentMethod>,
    installments: Array<IBillingInstallment>|[],
    version: number,
    organization_name: string,
    id: string,
    label: string,
    model: string,
}

export interface ICompany {
    id: string,
    name: string
}

export interface IParams {
    customerId: string,
    invoiceId: string
}

export interface IOrganization {
    future_id: string,
    slug: string,
    name: string,
    id: number,
}

export interface IBillingControllerProps {
    params: IParams,
    route: object,
    organization: IOrganization
}

export interface IBillingControllerState {
    contactCredit: IContactCredit,
    paymentMethods: Array<IPaymentMethod>,
    installmentList: Array<IInstallment>,
    addressList: Array<IAddress>,
    isFree: boolean,
    creditApplied: number,
    contactUuid: string,
    contactSubscribedToMailingList: boolean,
    paymentErrors: string,
    processingPayment: boolean,
    shouldSaveMailingList: boolean,
    contactMailingId: number,
    redirectionTimer: number,
    selectedInstallmentId: string | null,
    selectedPaymentMethodId: number | null,
}

export interface ISummary {
    applied_coupon: ICoupon|null,
    price_detail: IPriceDetail,
    lines: Array<ILine>
}

export interface IPriceDetail {
    subtotal: number,
    total: number,
    balance: number,
    adjustments: Array<IAppliedAdjustment>,
    taxes: Array<ITax>
}

export interface ICoupon {
    adjustment_id: number,
    code: string
}

export interface IContactCredit {
    can_use_credit: boolean,
    credit: number,
}

export interface ICountry {
    id: string,
    name: string,
    slug: string,
    states: IState[]|null,
}


export interface ILine {
    id: number,
    product: IProduct,
    subscriber: ISubscriber,
    applied_adjustments: Array<IAppliedAdjustment>,
    taxes: Array<ITax>,
    final_price: number,
}

export interface ISubscriber {
    id: number;
    full_name: string
}

export interface IProduct {
    name: string,
    description: string,
    image_path: string,
    image_alt: string,
    price: number
    price_before_taxes: number,
    type_plan: string,
    reserved_sessions: Array<IReservedSession>
    product_start_date: string,
    product_end_date: string,
    quantity: number,
    duration: number,
}

export interface IReservedSession{
    name: string,
    start_date: string,
    end_date: string
}

export interface IAppliedAdjustment {
    id: number,
    value: number,
    name: string|undefined,
}

export interface ITax {
    id: number,
    name: string,
    rate: number,
    application_order: number,
    value: number,
    system_tax_id: string,
    is_percentage: boolean
}

export interface IPaymentMethod {
    id: number,
    name: string|null,
    message_to_payer: string|null,
    type: string,
    icon: string,
    installment_ids: Array<number>,
    can_pay_now: boolean,
}

export interface IInstallment {
    id: number,
    payments: Array<IInstallmentPayment>,
    future_id: string,
}

export interface IInstallmentPayment {
    amount: number,
    date: string,
}

export interface IBillingInstallment {
    id: number,
    amount: number,
    status: string|null,
    due_date: string,
}

export interface IAddress {
    id: number,
    type_address: string,
    civic_number_and_street: string,
    civic_number_ext: string|null,
    postal_code: string,
    country: ICountry,
    state: IState,
    city: ICity,
}

export interface IState {
    id: number,
    name: string,
    abbreviation: string,
    slug: string
}

export interface ICity {
    id: number,
    name: string,
    country_id: string,
    state_id: number,
}

export interface IBillingAddress {
    id: number|null,
    civic_number_ext: string|null,
    civic_number_and_street: string,
    country: string,
    state: string,
    state_id: number,
    city: string,
    city_id: number|null,
    postal_code: string,
    willBeSaved: boolean,
}

export interface IBankAccount {
    holder_name: string,
    number: string,
    transit_number: string,
    institution: {label: string, value: string}|null,
    type: {label: string, value: string}|null
}

export interface IAddressError {
    field: string,
}

export interface ILoadedStatesCities {
    state: number,
    cities: Array<ICityOption>
}

export interface ICityOption {
    label: string,
    value: number
}

export interface IBillingViewProps {
        contactCredit: IContactCredit,
        paymentMethods: Array<IPaymentMethod>,
        addressList: Array<IAddress>,
        handleTokenBadRequest: (code: string) => void,
        handlePayment: (
            credit: number,
            version: number,
            paymentMethodId: number|null,
            token: string|null,
            installmentId: string|null,
            coupon: ICoupon|null,
        ) => void,

        applyCredit: (credit: number) => void,
        paymentErrors: string,
        processingPayment: boolean,
        organizationUuid: string,
        redirectionTimer: number,
        organizationName: string,
        selectedPaymentMethodId: number | null,
        isFree: boolean,
}

export interface IBillingViewState {
    credit: number,
}

export interface IPaymentErrorsProps {
    paymentErrors: string,
    redirectionTimer: number,
    organizationName: string,
}

export interface IFinalizeBoxProps {
    isLoading: boolean,
    shouldShowPaymentBox: boolean,
    paymentMethods: Array<IPaymentMethod>,
    addressList: Array<IAddress>,
    installmentList: Array<IInstallment>,
    handleTokenBadRequest: (code: string) => void,
    handlePayment: (
        credit: number,
        version: number,
        paymentMethodId: number|null,
        token: string|null,
        installmentId: string|null,
        coupon: ICoupon|null,
    ) => void,
    paymentErrors: object,
    showPaymentErrorBox: boolean,
    processingPayment: boolean,
    organizationUuid: string,
    billingInstallments: Array<IBillingInstallment>
    selectedInstallmentId: string | null,
    selectedPaymentMethodId: number | null,
    version: number,
    lineCount: number,
    appliedCoupon: ICoupon|null,
    credit: number,
    handleContinueShoppingClicked: () => void,
    disabled: boolean
}

export const PAY_NOW = 1;
export const PAY_INSTALLMENTS = 2;

interface TransitValidation {
    regex: string|null,
    placeholder: string|null,
    error: string|null,
    fixedValue: string|null
}

interface AccountValidation {
    regex: string|null,
    placeholder: string|null,
    error: string|null
}

export interface IBank {
    institutionSlug: string,
    institutionName: string,
    institutionNumber: string,
    transit: TransitValidation,
    account: AccountValidation
}

export interface IRawBankResponse {
    institution_slug: string,
    institution_name: string,
    institution_number: string,
    transit: {
        regex: string,
        placeholder: string,
        error: string,
        fixed_value: string,
    },
    account: {
        regex: string,
        placeholder: string,
        error: string,
    }
}

export interface IPendingOrderResponseException {
    type: string,
    title: string,
    error_code: string,
    does_payment_passed: boolean,
    instance: string,
    status: string,
    details: string
}
