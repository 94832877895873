import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Checkbox              from "qidigo-form/checkbox.js";

const toClass = (name) =>
	name.replace(/[^a-zA-Z0-9]/g, "_");

/**
 * Boutons togglables (checkboxes) combinables.
 *
 * C'est-à-dire que plusieurs peuvent être sélectionnés.
 *
 * #### Notes:
 *
 *   * L'ordre des boutons doit être géré à l'externe.
 *   * L'ordre suit l'array `list`.
 *   * L'ordre de `selected` n'est pas important.
 *   * Le changement d'état des boutons doit être contrôlé.
 *
 * ### `props.invertSelection`
 *
 * Lorsque `true`, l'array `selected` est inversé, c'est-à-dire que le contenu de `selected`
 * est en réalité `unchecked`. Permet d'inverser la logique de base pour ne pas nécessiter de
 * remplir `selected` avec les valeurs initiales.
 *
 * ### `props.labels`
 *
 * Il est possible que les valeurs à sélectionner diffèrent des labels à utiliser.
 *
 * Lorsque `props.labels` est renseigné, les labels seront retirés par key dans l'object.
 *
 * @extends {Component}
 */
class FilterButtons extends Component {
	shouldComponentUpdate(b) {
		const a = this.props;

		return (
			a.className         !== b.className ||
			a.list              !== b.list ||
			a.selected          !== b.selected ||
			a.onSelectionChange !== b.onSelectionChange
		);
	}

	handleClick(e, value) {
		e.preventDefault();
		let newSelected = [].concat(this.props.selected);
		if (newSelected.indexOf(value) === -1) {
			newSelected.push(value);
		}
		else {
			newSelected = newSelected.filter((v)=>v!==value);
		}
		this.props.onSelectionChange(newSelected);
	}

	render() {
		const {
			className,
			list,
			selected,
			invertSelection,
			labels,
		} = this.props;
		const classes = [className, "filterbuttons"];
		const buttons = Object.entries(list).map(([text, value]) => {
			let label = text;
			if (labels && labels[text]) {
				label = labels[text];
			}
			return (
				<li
					className="filterbuttons--button"
					key={value}
				>
					<Checkbox
						className={`id-${toClass(value.toString())}`}
						baseClass="togglebutton"
						onChange={(e)=>this.handleClick(e, value)}
						label={label}
						value={(selected.indexOf(value) !== -1) !== invertSelection}
					/>
				</li>
			);
		});

		return (
			<ul className={classes.join(" ")}>
				{buttons}
			</ul>
		);
	}
}

FilterButtons.propTypes = {
	className:    PropTypes.string,
	list:         PropTypes.array.isRequired,
	selected:     PropTypes.array.isRequired,
	onSelectionChange: PropTypes.func.isRequired,
	invertSelection: PropTypes.bool,
	labels: PropTypes.object,
};

FilterButtons.defaultProps = {
	invertSelection: false,
};

export default FilterButtons;
