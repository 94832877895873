import React                 from "react";
import PropTypes             from "prop-types";
import ButtonsBar            from "qidigo-form/buttons_bar";
import Form                  from "qidigo-form/form";
import Helmet                from "react-helmet";
import map                   from "lodash/map";
import TitledSection         from "./titled-section";
import Button                from "qidigo-components/button.js";
import Question              from "./questions";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import BackToLastLocation from "@app/components/back-to-last-location.js";


/**
 * Display a qidigo form
 */

const translations = defineMessages({
	cancel: {id: "qidigo.dashboard.form.cancel", defaultMessage: "Annuler"},
	contains_errors: {id: "qidigo.dashboard.form.contains_errors", defaultMessage: "Ce formulaire contient une ou des erreurs"},
});

const FormView = (props) => {
	const {
		onCancel,
		form,
		sections,
        questions,
        questionOrder,
        onChange,
        onSubmit,
        answers,
        errors,
		sent,
	} = props;

	const containsErrorsMessage = <div className="error-message"><FormattedMessage {...translations["contains_errors"]} /></div>;

	let content = null;

	if (sections && sections.length > 0) {
		content = map(sections, (section, i) => {
			if (section.questionIds!=="") {
				return <TitledSection key={i} className="form-section" title={section.title} description={section.description} >
					{map(section.questionIds.split(","), (id) => {
						if (questions[id]) {
							return <Question className="question" name={`${id}`} key={id} question={questions[id]} answer={answers[`${id}`]}  error={errors[id]} formId={props.params.id}/>;
						}

						return null;
					})}
				</TitledSection>;
			}

			return null;
		});
	} else {
		content  = <TitledSection className="form-section" >
			{map(questionOrder, (id) => {
				if (questions[id]) {
					return <Question className="question" name={`${id}`} key={id} question={questions[id]} answer={answers[id]} error={errors[id]} formId={props.params.id}/>;
				}

				return null;
			})}
		</TitledSection>;
	}
	
	return (
		<section className="dashboard-form">
			<div className={"dashboard-form--back-to-last-location-container"}>
				<BackToLastLocation/>
			</div>
			<div className="form-username">
				{form.user.first_name} {form.user.last_name}
			</div>
			<h1 className={"dashboard-form--form-title"}><span>{form.title}{errors ? errors.length : null}</span></h1>
			<Helmet
				title={form.title}
			/>
			<div className="form-desciption" dangerouslySetInnerHTML={{__html: form.description}}/>
			<Form
				onChange={onChange}
				onSubmit={onSubmit}
			>
				{Object.keys(errors).length > 0 && containsErrorsMessage}
				{content}
				<ButtonsBar
					disabled={props.sent  && Object.keys(errors).length == 0}
				>
					<Button onClick={(e) => onCancel(e)}>
						<FormattedMessage {...translations["cancel"]} />
					</Button>
				</ButtonsBar>
			</Form>
		</section>
	);
};

FormView.defaultProps = {
	answer : {},
	sections: null,
	errors : {}
};

FormView.propTypes = {
	handleCancelAction: PropTypes.func,
	form: PropTypes.object,
	sections: PropTypes.array,
	errors: PropTypes.object,
	questions : PropTypes.object,
	questionOrder : PropTypes.array,
	answers : PropTypes.object,
	onChange : PropTypes.func,
	onCancel : PropTypes.func,
	onSubmit : PropTypes.func
};

FormView.contextTypes = {
	intl: PropTypes.object,
};

export default FormView;
