import {path} from "@app/lib/routes";
import {addBatchToCart} from "@app/store/actions/buyBatch";
import {openCart} from "@app/store/actions/cart";
import PropTypes from "prop-types";
import Loading from "qidigo-components/loading.js";
import Fetch from "qidigo-fetch";
import Logger from "qidigo-logger";
import {navigate} from "qidigo-router";
import React, {Component} from "react";
import {connect} from "react-redux";
import flatten from 'lodash/flatten'
import intersection from 'lodash/intersection'
import {changeShouldShowCompleteGroups} from "../../store/actions/groups";
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import {ALERT_TYPE} from "../../components/alert";
import {defineMessages, FormattedMessage} from "react-intl";
import {withAlerts} from "../../HOCs/withAlerts";
import {makeFormattedMessage} from "../../utils/alertMessages";
import MemberOffersSelection from "../../views/activity/MermberOffersSelection";

function getSelectedOffers(offers, selectedOffers) {
    return offers.filter(plans => plans.some(
        x => intersection(
            x.plan.groups.map(g => String(g.id)),
            Object.keys(selectedOffers)
        ).length > 0
    ));
}

const messages = defineMessages({
    restrictedItemList: {
        id: "qidigo.activity.groups.page.batch.restricted_item_list",
        defaultMessage: "{product_name} pour {subscriber_name}"
    },
    restrictedConjunction: {id: "qidigo.activity.groups.page.batch.restricted_conjunction", defaultMessage: " et "},
    restrictedMessage: {
        id: "qidigo.activity.groups.page.batch.restricted_message",
        defaultMessage: ` {itemCount, plural,
			 one {n'est plus disponible et n'a pas été ajouté au panier ou à la liste d'attente}
			 other {ne sont plus disponibles et n'ont pas été ajoutés au panier ou à la liste d'attente}}`
    },
});

const GROUP_IS_FULL = 'GROUP_IS_FULL';
const SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS = 'SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS';

class GroupsSelectedController extends Component {

    constructor() {
        super();
        this.state = {
            disabled: false,
            isLoading: false,
            sessions: null,
            selectedMembers: [],
            selectedOffers: [],
            passes: null,
            reservations: null,
            errors: false,
            adding: false,
            groups: [],
            members: [],
            member: null,
            offers: [],
            offer: [],
            newStatus: {},
            groupsOffers: false,
            selected: [],
            fetchingOffers: [],
            onAddingCart: false,
            hasRestrictedSelection: false,
        }
    }

    componentDidMount() {
        this.props.changeShouldShowCompleteGroups(this.props.location.query.shouldShowCompleteGroups === "true")

        let selectedMembers = this.props.location.query.selectedMembers.split(",").filter(x => !Number.isNaN(Number(x)));

        this.setState(
            {
                selected: this.props.location.query.groups
                    ? this.props.location.query.groups.split(",").filter(x => !Number.isNaN(Number(x)))
                    : [],
                selectedMembers: selectedMembers.map((member, key) => {
                    return {
                        id: Number(member),
                        selectedOffers: [],
                        selectedGroups: this.props.location.query.groups
                            ? this.props.location.query.groups.split(",").filter(x => !Number.isNaN(Number(x)))
                            : [],
                        newStatus: [],
                    }
                }),
                groups: this.props.groups || []
            },
            () => {
                if (this.state.selectedMembers.length === 0) {
                    navigate(path(`activity.groups`, {
                        orgID: this.props.activity.organization["slug"],
                        activityID: this.props.activity.id
                    }), {
                        search: "?groups=" + this.state.selected.toString(),
                    })
                    return
                }
                this.updateOffers();
                if (this.state.selectedMembers !== null) {
                    const selectedMemberIds = this.state.selectedMembers.map((member) => {
                        return member.id
                    })

                    Fetch.get(`activities/${this.props.activity.id}/groups?subscriber_ids=${JSON.stringify(selectedMemberIds)}&with_waiting_list=true&with_default=0`)
                        .then(({groups}) => {
                            this.setState({
                                groups
                            })
                        })
                }
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.cartItems.length !== this.props.cartItems.length) {
            this.updateOffers();
        }
    }

    autoCatch(promise) {
        // Enchaîne la promise...
        return promise
            .then((response) => {
                return response;
            })
            // Et le *catching* par défaut.
            .catch(Logger.catcher);
    }

    handlePreviousStep = () => {
        navigate(path(`activity.groups`, {
            orgID: this.props.activity.organization["slug"],
            activityID: this.props.activity.id
        }), {
            search: "?groups=" + this.state.selected.toString()
                + "&selectedMembers=" + this.props.location.query.selectedMembers
                + "&shouldShowCompleteGroups=" + this.props.shouldShowCompleteGroups,
        })
    }

    handleAddToCartForAll = async () => {
        const {dispatch, setAlertMessage} = this.props;
        this.setState({
            isLoading: true
        });

        const cartItems = this.getSelectedOffersForCart(false);
        let cartItemsToAdd = Array();

        for (const [groupId, planId] of cartItems) {
            const members = this.state.selectedMembers.filter((member) => {
                return Object.entries(member.selectedOffers).find(([memberGroupId, memberPlanId]) => {
                    return Number(memberPlanId) === planId;
                })
            })

            cartItemsToAdd.push(...members.map((member) => {
                return {
                    quantity: 1,
                    subscriber_user_id: Number(member.id),
                    plan_id: Number(planId),
                }
            }));
        }

        try {
            const responseItems = await dispatch(addBatchToCart({
                cart_items: cartItemsToAdd
            }))

            if (responseItems.restrictedItems.length > 0) {
                const offersList = flatten(this.state.offers)
                    .filter((offers) => responseItems.restrictedItems.find(x => {
                        return x.product_id === offers.plan.id
                    })).map((offers) => {
                        return {
                            plan: offers.plan,
                            isWaitingList: offers.options[0].restrictions.length === 1 && offers.options[0].restrictions[0] === GROUP_IS_FULL,
                        }
                    });

                const restrictedOptionList = responseItems.restrictedItems.map(
                    item => {
                        const offer = offersList.find(offer => offer.plan.id === item.product_id);
                        return {
                            planName: offer.plan.name,
                            subscriberName: this.state.members[item.family_member_id].full_name,
                            isWaitingList: offer.isWaitingList,
                            exceedsMaxRegistrations: this.containsRestriction(item, 'SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS')
                        }
                    }
                );

                setAlertMessage({
                    message: makeFormattedMessage(restrictedOptionList),
                    type: ALERT_TYPE.ERROR
                });
            } else {
                setAlertMessage({});
            }
        } catch (e) {
            if (e.response && e.response.status) {
                const newStatus = Object.assign({}, this.state.newStatus, {
                    [planId]: e.response.status,
                });
                this.setState({newStatus});
            }
        }

        if (this.props.location.query.groups) {
            this.setState({
                selected: this.props.location.query.groups.split(","),
                isLoading: false
            })
        }

        navigate(
            path(
                `activity.groups`,
                {orgID: this.props.activity.organization["slug"], activityID: this.props.activity.id}
            )
        )

        this.props.dispatch(openCart());
    }

     containsRestriction = (response, restriction) => {
        const groupsRestrictions = response.error.groups_restrictions;
        
        return !!(groupsRestrictions.length > 0 && groupsRestrictions[0].restrictions.includes(restriction));
    }

    handleSelectPlan = (id, shouldUpdatePrice, requestedMemberId) => {
        if (id.split(":").length == 1) {
            return null;
        }

        let {selectedMembers, selectedOffers} = this.state;
        let key = this.state.selectedMembers.findIndex(member => member.id === requestedMemberId);

        selectedMembers[key].selectedOffers = {
            ...selectedMembers[key].selectedOffers,
            [id.split(":")[0]]: id.split(":").length > 1 ? id.split(":")[1] : null
        };

        this.setState({
                selectedMembers: selectedMembers,
            },
            () => {
                this.getIsDisabledForChangingPlan(requestedMemberId)
                this.setHasRestrictedSelection()
                if (shouldUpdatePrice) {
                    this.updatePriceForSelectedOffer();
                }
            });
    }

    onDelete = (groupId, memberId) => {
        let selectedMembers = this.state.selectedMembers;
        const memberKey = selectedMembers.findIndex((member) => {
            return member.id === memberId
        });

        selectedMembers[memberKey].selectedGroups =
            selectedMembers[memberKey].selectedGroups.filter(id => id !== String(groupId));

        if (Object.keys(selectedMembers[memberKey].selectedOffers).length > 0) {
            selectedMembers[memberKey].selectedOffers = Object.fromEntries(
                Object.entries(selectedMembers[memberKey].selectedOffers)
                    .filter(([key, value]) => {
                        return Number(key) !== groupId
                    })
            );
        }

        const hasSelectedGroups = selectedMembers[memberKey].selectedGroups.length > 0;
        if (!hasSelectedGroups) {
            selectedMembers =  selectedMembers.filter(member =>
                member.id !== selectedMembers[memberKey].id
            )
        }

        const membersSelectedGroupsCount = this.state.selectedMembers.reduce((total, member) => {
            return total + member.selectedGroups.length;
        }, 0);

        this.setState({
            selectedMembers: selectedMembers,
            fetchingOffers: [],
        }, () => {
            if (membersSelectedGroupsCount === 0) {
                this.handlePreviousStep();
            } else {
                navigate(path(`groups.selected`, {
                        orgID: this.props.activity.organization["slug"],
                        activityID: this.props.activity.id
                    }),
                    {
                        search: "?groups=" + this.state.selected.toString() + "&selectedMembers=" + this.props.location.query.selectedMembers
                    }
                );
                this.setHasRestrictedSelection();
                this.getIsDisabledForChangingPlan(hasSelectedGroups ? memberId : null);
                if (hasSelectedGroups) {
                    this.checkGroupsOffersAndGroupsWaiting(
                        getSelectedOffers(this.state.offers, selectedMembers[memberKey].selectedOffers)
                    );
                }
                this.updateOffers();
            }
        });
    }

    onAddToWaitingList = async (planId, memberId) => {
        const {dispatch, setAlertMessage} = this.props;
        this.setState({
            isLoading: true
        })
        const responseItems = await dispatch(addBatchToCart({
            cart_items: [
                {
                    quantity: 1,
                    subscriber_user_id: memberId,
                    plan_id: planId,
                }
            ]
        }))

        if (responseItems.restrictedItems.length > 0) {
            const planList = flatten(this.state.offers)
                .filter((offers) => responseItems.restrictedItems.find(x => {
                    return x.product_id === offers.plan.id
                }))
                .map((offers) => offers.plan);

            const restrictedOptionList = responseItems.restrictedItems.map(
                item => {
                    return {
                        planName: planList.find(plan => plan.id === item.product_id).name,
                        subscriberName: this.state.members[item.family_member_id].full_name,
                        isWaitingList: true
                    }
                }
            );

            setAlertMessage({
                message: makeFormattedMessage(restrictedOptionList),
                type: ALERT_TYPE.ERROR
            });
            this.props.dispatch(openCart());
        } else {
            const memberKey = this.state.selectedMembers.findIndex((member) => {
                return member.id === memberId;
            })

            const selectedMembers = this.state.selectedMembers;
            selectedMembers[memberKey].newStatus =
                Object.assign({
                    [planId]: "WAITING_LIST",
                });

            this.setState({
                selectedMembers: selectedMembers
            })
        }
        this.setState({
            isLoading: false
        })
    }

    updateOffers = () => {
        this.setState({
            isLoading: true
        })
        const selectedMemberIds = this.state.selectedMembers.map((member) => {
            return member.id
        })
        const groupIdsSearch = 'group_ids[]=' + this.state.selected.join('&group_ids[]=')
        const subscriberIdsSearch = '&subscriber_ids[]=' + selectedMemberIds.join('&subscriber_ids[]=')

        Fetch.get(`activities/${this.props.activity.id}/groups_offers?${groupIdsSearch}${subscriberIdsSearch}`)
            .then(({groups_offers}) => {
                const {offers, _bundled: {family_members}} = groups_offers
                const selectedOffers = this.state.selected.reduce((acc, groupId) => {
                    return {
                        ...acc,
                        [groupId]: 0
                    }
                }, {});

                this.setState({
                    offers,
                    members: family_members,
                    isLoading: false,
                    selectedOffers: selectedOffers,
                }, () => {
                    this.getIsDisabledForChangingPlan()
                    this.checkGroupsOffersAndGroupsWaiting(this.state.offers)
                });
            })
            .then(() => {
                this.updatePriceForSelectedOffer();
            });

        if (this.state.selected.length === 0 || this.state.selectedMembers === null) {
            navigate(path(`activity.groups`, {
                orgID: this.props.activity.organization["slug"],
                activityID: this.props.activity.id
            }))
        }
    }

    checkGroupsOffersAndGroupsWaiting(offers) {
        const selectedMemberIds = this.state.selectedMembers.map(member => member.id);

        this.setState({
            groupsOffers: offers.some(innerOffers => {
                return innerOffers.some(offer => {
                    return offer.options.some(option => selectedMemberIds.includes(option.subscriber))
                        && !offer.options.some(option => this.restrictionIncludeGroupIsFull(option.restrictions))
                        && offer.plan.groups.some(group => this.state.selected.includes(String(group.id)))
                })
            }),
        })
    }

    getMembersSelectedOffers = () => {
        const filteredPairs = [];

        if (!this.state.selectedOffers) {
            return filteredPairs;
        }

        this.state.selectedMembers.forEach((member) => {
            filteredPairs.push(...toPairs(member.selectedOffers))
        })

        return fromPairs(filteredPairs);
    }

    getSelectedOffersForCart = (shouldReturnRestricted) => {
        const allOffers = flatten(this.state.offers);

        return Object.entries(this.getMembersSelectedOffers())
            .map(([groupId, planId]) => [groupId, Number(planId)])
            .filter(([groupId, planId]) => {
                const selectedOffer = allOffers.find(offer => {
                    return offer.plan.id === planId && offer.offer_for_group_id === parseInt(groupId);
                });

                if (!selectedOffer) {
                    return false;
                }
                const selectedMembersIds = this.state.selectedMembers.map((member) => {
                    return member.id
                })
                const optionForUser = selectedOffer.options.find(option => selectedMembersIds.includes(option.subscriber));

                if (
                    !optionForUser
                    || optionForUser.restrictions.groups_restrictions.length > 1
                    || optionForUser.restrictions.membership_restrictions.length > 0
                    || optionForUser.restrictions.product_restrictions.length > 0
                    || optionForUser.restrictions.registration_restrictions.length > 0
                    || (optionForUser.restrictions.groups_restrictions[0] 
                        && optionForUser.restrictions.groups_restrictions[0] !== GROUP_IS_FULL)
                ) {
                    return shouldReturnRestricted;
                }
                return !shouldReturnRestricted;
            });
    }

    restrictionIncludeGroupIsFull = (restrictions) => {
        return restrictions.product_restrictions.includes(GROUP_IS_FULL)
    }

    getIsDisabledForChangingPlan(memberId) {
        const selected = this.state.selected
        memberId = memberId ? memberId : null;

        if (selected && selected.length > 0) {

            const selectedOffers = getSelectedOffers(
                this.state.offers,
                this.getMembersSelectedOffers()
            );

            this.setState({
                disabled: getSelectedOffers(
                    this.state.offers,
                    this.getMembersSelectedOffers()
                )
                    .filter(plans => plans.some(
                        x => x.options.every(option => !this.restrictionIncludeGroupIsFull(option.restrictions)))
                    )
                    .every(plans => !this.allSelectedPlansAreEligible(plans))
                    || !this.everyGroupHasPlanSelected()
                    || (memberId && this.selectedOfferHasMaxSubscriptionRestriction(memberId))
            })
        } else {
            this.setState({
                disabled: true
            });
        }
    }

    getHasRestrictedSelection() {
        return this.getSelectedOffersForCart(true).length > 0;
    }

    setHasRestrictedSelection() {
        this.setState({
            hasRestrictedSelection: this.getHasRestrictedSelection()
        });
    }

    updatePriceForSelectedOffer() {
        const body = {
            "items": [],
            "organization_id": this.props.activity.organization.id
        };

        const allOffers = flatten(this.state.offers);

        const cartableItems = Object.entries(this.getMembersSelectedOffers())
            .map(([groupId, planId]) => [groupId, Number(planId)])
            .filter(([groupId, planId]) => {
                const selectedOffer = allOffers.find(x => {
                    return x.plan.id === planId
                })

                if (!selectedOffer) {
                    return false;
                }

                const optionsForUsers = this.state.selectedMembers.map(member => {
                    const options = selectedOffer.options.find(option => Number(option.subscriber) === Number(member.id));
                    return {member, options};
                });
                if (!optionsForUsers) {
                    return false
                }

                optionsForUsers.forEach((userOption) => {
                    if (userOption.options.restrictions.length > 0) {
                        return false
                    }
                })

                return true;
            });

        cartableItems.forEach(cartableItem => {
            this.state.selectedMembers.forEach((member) => {
                if (member.selectedGroups.includes(cartableItem[0])) {
                    body.items.push({
                        product_id: cartableItem[1],
                        family_member_id: Number(member.id)
                    })
                }
            })
        });

        (this.props.cartItems.filter(cartItem => cartItem.organization.id === this.props.activity.organization.id) || []).forEach(cartItem => {
            body.items.push({
                product_id: cartItem.plan.id,
                family_member_id: cartItem.subscriber.id
            })
        });

        if (body.items.length > 0) {
            Fetch.post('simulation/order_with_cart', body)
                .then((response) => {
                    this.setNewOffersPrices(response);
                });
        }

    }

    setNewOffersPrices = (response) => {
        const offers = this.state.offers;
        const updatedOffers = [];
        const items = Object.values(response.items);

        offers.forEach(parentOffer => {
            const offers = [];
            parentOffer.forEach(offer => {
                this.state.selectedMembers.forEach((member) => {
                    const optionKey = offer.options.findIndex((option) => {
                        return option.subscriber === member.id
                    })

                    const newPrice = items.find(item => {
                        return Number(item.product_id) === Number(offer.plan.id) && Number(item.family_member_id) === Number(offer.options[optionKey].subscriber);
                    });

                    if (newPrice) {
                        offer.options[optionKey].price = newPrice.final_price;
                    }
                })
                offers.push(offer);
            });

            updatedOffers.push(offers);
        });

        this.setState({
            offers: updatedOffers
        });
    }

    makeFormattedMessage = (items) => {
        if (items.length === 0) {
            return "";
        }

        if (items.length === 1) {
            return (
                <>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: items[0].planName,
                            subscriber_name: items[0].subscriberName,
                        }}
                    />
                    <FormattedMessage {...messages.restrictedMessage} values={{itemCount: items.length}}/>
                </>
            );
        }

        if (items.length > 1) {
            return (
                <>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: items[0].planName,
                            subscriber_name: items[0].subscriberName,
                        }}
                    />
                    {items.slice(1, -1).map(item =>
                        <>
                            {", "}
                            <FormattedMessage
                                {...messages.restrictedItemList}
                                values={{
                                    product_name: item.planName,
                                    subscriber_name: item.subscriberName,
                                }}
                            />
                        </>
                    )}
                    <FormattedMessage {...messages.restrictedConjunction}/>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: items.slice(-1)[0].planName,
                            subscriber_name: items.slice(-1)[0].subscriberName,
                        }}
                    />
                    <FormattedMessage {...messages.restrictedMessage} values={{itemCount: items.length}}/>
                </>
            );
        }
    }

    allSelectedPlansAreEligible = (plans) =>  {
        let areSelectedPlansEligible = true;

        for (const member of this.state.selectedMembers) {
            const selectedPlan = plans.find(x => {
                return Object.values(member.selectedOffers).includes(String(x.plan.id))
            })

            if (!selectedPlan) {
                areSelectedPlansEligible = false
                break;
            }

            const selectedOption = selectedPlan.options.find(x => x.subscriber === Number(member.id))
            if (!selectedOption) {
                throw new Error(`No option for id: ${member.id}`)
            }
            const hasRestriction = selectedOption.restrictions.groups_restrictions.length > 0 ||
                selectedOption.restrictions.membership_restrictions.length > 0 ||
                selectedOption.restrictions.organization_restrictions.length > 0 ||
                selectedOption.restrictions.registration_restrictions.length > 0 ||
                selectedOption.restrictions.product_restrictions.length > 0

            areSelectedPlansEligible = !(!selectedOption.restrictions.product_restrictions.find(
                (restriction) => restriction.code ===GROUP_IS_FULL) !== undefined 
                && hasRestriction);

            if (!areSelectedPlansEligible) {
                break;
            }

            const isSelectedOfferHasMaxSubscriptionRestriction = selectedOption.restrictions.groups_restrictions.some(
                (groupRestrictions) => groupRestrictions.some(
                    (restriction) => (restriction.code ? restriction.code : restriction) === SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS
                )
            );

            if (isSelectedOfferHasMaxSubscriptionRestriction) {
                areSelectedPlansEligible = false;
                break;
            }
        }

        return areSelectedPlansEligible;
    }

    everyGroupHasPlanSelected = () => {
        for (const member of this.state.selectedMembers) {
            for (const key in member.selectedOffers) {
                if (member.selectedOffers[key] === 0) {
                    return false;
                }
            }
        }

        return true;
    }

    selectedOfferHasMaxSubscriptionRestriction = (memberId) => {

        const member = this.state.selectedMembers.find((member) => member.id === memberId);

        if (member === undefined) {
            throw new Error('Member not found');
        }

        for (const group in member.selectedOffers) {
            const currentPlan = member.selectedOffers[group];
            if (currentPlan === 0) {
                continue;
            }
            
            const selectedGroupOffers = this.state.offers.find((offers) => {
                return offers.find(
                    (offer) => offer.plan.id === Number(currentPlan) && offer.offer_for_group_id === parseInt(group)
                        && offer.options.find((option) => option.subscriber === member.id) !== undefined
                    )
            });

            if (selectedGroupOffers === undefined) {
                throw new Error('Offers not found');
            }

            const selectedProduct = selectedGroupOffers.find((option) => option.plan.id === Number(currentPlan));

            if (selectedProduct === undefined) {
                throw new Error('Offer not found');
            }

            const memberProductOption = selectedProduct.options.find((option) => option.subscriber === member.id);

            if (memberProductOption === undefined) {
                throw new Error('Option not found');
            }

            if (memberProductOption.restrictions.groups_restrictions.length === 0) {
                continue;
            }

            const hasMaxSubscriptionRestriction = memberProductOption.restrictions.
            groups_restrictions.find((groupRestrictions) => {
                return groupRestrictions.restrictions.some((restriction) => {
                    const restrictionCode = restriction.code ? restriction.code : restriction;
                    return restrictionCode === SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS
                })
            }) !== undefined;

            if (hasMaxSubscriptionRestriction) {
                return true;
            }
        }

        return false;
    }


    render() {
        const {
            route,
            params,
            activity,
            offers,
            members,
            selectedMembers = [],
            selected,
            ...props
        } = this.props;

        const {
            groups,
        } = this.state

        if (!activity || !groups) {
            return <Loading/>;
        }

        return (
            <MemberOffersSelection
                handlePreviousStep={this.handlePreviousStep}
                handleAddToCartForAll={this.handleAddToCartForAll}
                handleSelectPlan={this.handleSelectPlan}
                onDelete={this.onDelete}
                onAddToWaitingList={this.onAddToWaitingList}
                activity={activity}
                groups={groups}
                offers={this.state.offers}
                groupsOffers={this.state.groupsOffers}
                newStatus={this.state.newStatus}
                disabled={this.state.disabled || this.state.isLoading}
                isLoading={this.state.isLoading}
                members={this.state.members}
                refreshOptions={this.updateOffers}
                hasRestrictedSelection={this.state.hasRestrictedSelection}
                cartItems={this.props.cartItems}
                changeAlertMessage={this.props.setAlertMessage}
                openCart={openCart}
                organization={this.props.activity.organization}
                selectedMembers={this.state.selectedMembers}/>
        )
    }
}

GroupsSelectedController.propTypes = {
    activity: PropTypes.object,
    groups: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps(state) {
    return {
        cartItems: state.cart.items || [],
        shouldShowCompleteGroups: state.groups.shouldShowCompleteGroups
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeShouldShowCompleteGroups(shouldShowCompleteGroups) {
            dispatch(changeShouldShowCompleteGroups(shouldShowCompleteGroups))
        }
    }
}

export default withAlerts(
    connect(mapStateToProps, mapDispatchToProps)(GroupsSelectedController)
);
