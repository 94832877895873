import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Title                 from "./title";
import {
	Link,
	IndexLink,
} from "react-router/es";
import Input               from "qidigo-form/input.js";
import Pager               from "@app/components/pager.js";
import FilterButtons       from "@app/components/filterbuttons.js";
import Activities          from "@app/views/shared/activities.js";

import {
	defineMessages,
	FormattedMessage
} from "react-intl";

const translations = defineMessages({
	"page.title":   {id: "qidigo.organization.session_activities.page.title", defaultMessage: "Activités à la session"},
	"searchTerm":   {id: "qidigo.organization.session_activities.search_term", defaultMessage: "Recherche"},
});

/**
*/
const OrganizationSessionActivitiesView = (props, context) => {
	const {formatMessage} = context.intl;
	const {
		organization,
		q,
		page,
		numberOfPages,
		selectedCategories,
		categories,
	} = props;

	return (
		<section className="h-card">
			<Title organization={organization} title={formatMessage(translations["page.title"])} />
			<h2>
				<FormattedMessage {...translations["page.title"]} />
			</h2>
			<div>
				<Input
					label={formatMessage(translations.searchTerm)}
					name="q"
					className="organization--search organization--q"
					presentation="simple" icon="search" type="search"
					value={q}
					onChange={(...e) => props.handleSearchChange(...e)}
				/>
				<FilterButtons
					list={categories}
					selected={selectedCategories}
					onSelectionChange={(newCats) => props.handleCategorySelection(newCats)}
				/>
			</div>
			<Activities
				organization={organization["id"]}
				q={q}
				selectedCategories={selectedCategories}
				onNumberOfPagesChanged={(p)=>props.handleNumberOfPagesChanged(p)}
				onCategoriesChanged={(c)=>props.handleCategoriesChange(c)}
				page={page}
			/>
			<Pager
				currentPage={page}
				numberOfPages={numberOfPages}
				onChange={(p)=>props.handlePageChange(p)}
			/>
		</section>
	);
};

OrganizationSessionActivitiesView.propTypes = {
	organization: PropTypes.object.isRequired,
	q: PropTypes.string.isRequired,
	page: PropTypes.number.isRequired,
	numberOfPages: PropTypes.number.isRequired,
	selectedCategories: PropTypes.array.isRequired,
	categories: PropTypes.array.isRequired,
	// Handlers.
	handlePageChange: PropTypes.func.isRequired,
	handleNumberOfPagesChanged: PropTypes.func.isRequired,
	handleCategorySelection: PropTypes.func.isRequired,
	handleCategoriesChange: PropTypes.func.isRequired,
	handleSearchChange: PropTypes.func.isRequired,
};

OrganizationSessionActivitiesView.contextTypes = {
	intl: PropTypes.object,
};

export default OrganizationSessionActivitiesView;

