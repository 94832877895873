import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Image                 from "qidigo-components/image.js";
import {Button}              from "qidigo-form";
import Collapse              from "react-collapse";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";

import HTML                  from "@app/components/html";
import OrganizationLink      from "@app/components/organization_link.js";
import Money                 from "@app/components/money.js";
import Duration              from "@app/views/shared/groups/duration";

const labels = defineMessages({
	"organization": {id: "qidigo.cart.organization", defaultMessage: "Entreprise"},
	"subscriber":   {id: "qidigo.cart.subscriber", defaultMessage: "Personne inscrite",},
	"colon":   {id: "qidigo.cart.subscriber.colon", defaultMessage: " :",},
	"withTaxesIfApplicable":    {id: "qidigo.cart.including_taxes_if_applicable", defaultMessage: "Incluant les taxes si applicables"},
	"remove":       {id: "qidigo.cart.remove", defaultMessage: "Retirer du panier"},
	"endMoment":    {id: "qidigo.cart.end_moment", defaultMessage: "Date limite d'inscription\u00A0: {date} à {time}."},
	"reservations.title": {id: "qidigo.cart.reservations.title", defaultMessage: "Réservations"},
	"reservation.remove": {id: "qidigo.cart.reservation.remove", defaultMessage: "Retirer la réservation"},
	"reservation.format": {id: "qidigo.cart.reservation.format", defaultMessage: "{name}, {date}"},
});

const details = defineMessages({
	"groups": {id: "qidigo.cart.details.groups",
		defaultMessage: `Valide pour {groupCount, plural,
			one {le groupe suivant}
			other {les {groupCount} groupes suivants}
		}`
	},
	"groups.show": {id: "qidigo.cart.details.groups_show",
		description: "Pour les sessions, ne pas utiliser la mention «valide»",
		defaultMessage: `Pour {groupCount, plural,
			one {le groupe suivant}
			other {les {groupCount} groupes suivants}
		}`
	},
});

/**
 * Sessions avec réservations en attente.
 */
const SessionReservation = ({reservation, handleDeleteReservation}, {intl}) => {
	const {formatMessage} = intl;
	return (
		<li className="cart-reservation">
			<FormattedMessage {...labels["reservation.format"]}
				values={{
					name: reservation.name,
					date: <Duration duration={[reservation.start_date, reservation.end_date]} withTime={true} concise={true} />,
				}}
			/>
			<Button
				className="cart-reservation--remove"
				onClick={() => handleDeleteReservation(reservation.id)}
				title={formatMessage(labels["reservation.remove"])}
			>
				<FormattedMessage {...labels["reservation.remove"]} />
			</Button>
		</li>
	);
};

SessionReservation.propTypes = {
	reservation: PropTypes.object.isRequired,
	handleDeleteReservation: PropTypes.func.isRequired,
};

SessionReservation.contextTypes = {
	intl: PropTypes.object,
};

/**
 * Un item.
 */
class CartItem extends Component {
	constructor() {
		super();
		this.state = {
			isOpened: false,
		};
	}

	toggleCollapse() {
		const {isOpened} = this.state;
		this.setState({isOpened: !isOpened});
	}

	render() {
		const {formatMessage} = this.context.intl;
		const {
			item,
			handleDeleteReservation,
		} = this.props;

		const classes = ["cart-item"];

		// (Wrongly) assumes one activity type.
		const {plan, organization} = item;
		const groups = plan.groups ? plan.groups.map((group) => {
			group = Object.assign({}, group);
			// Ré-hydrate l'activité, dans le but d'utiliser l'organisme pour le lien.
			group.activity = Object.assign({}, group.activity, {organization});

			return group;
		}) : [];
		const membership_template = plan.membership_template;

		let activity = null;

		if (groups && groups.length > 0) {
			activity = groups[0].activity;
		}

		// Note for membership media implementation : if there is a media on the template, use it.
		// If not, if the activity has one, take this one as a fallback.
		const media_holder = [membership_template, activity].filter((el) => el && el.media && el.media.path)[0];
		const media = media_holder && media_holder.media;
		const {isOpened} = this.state;
		const reservations = item.session_reservations;
		const type = plan["type_plan"];
		let detailsFragment = null;
        const count = groups.filter((group) => group.can_be_accessed_by_subscriber).length;

		const description = []
		// When a specific description is used.
		description.splice(0, description.length);
		description.push(
			<div
				key="offers.description"
			>
				<HTML
					contents={plan["description"]}
				/>
			</div>
		);

		// FIXME : Harmoniser la création de description pour un plan.
		if (type === "PASS") {
			detailsFragment =
				<div className={`cart-item--details type--PASS ${count===1?"count-one":"count-many"}`}>
					<p>
						{description}
					</p>
					<div className="cart-item--groups">
						<a
							onClick={(e)=>{ e.preventDefault(); this.toggleCollapse(); }}
							className={`collapse-link ${isOpened?"is-opened":"is-not-opened"}`}
						>
							<FormattedMessage {...details["groups"]}
								values={{
									groupCount: count,
								}}
							/>
						</a>
						<Collapse isOpened={isOpened}>
							<ul className="cart-item--groups">
								{groups.map((group) => 
                                    group.can_be_accessed_by_subscriber &&
                                    <li key={group.id}>
                                        {group.name}
                                    </li>
								)}
							</ul>
						</Collapse>
					</div>
				</div>
				;
		}
		else if (type === "SESSION" && groups.length > 0) {
			detailsFragment =
				<div>
					<p>
						{description}
					</p>
					{
						count > 1 ?
							<div className="cart-item--groups">
								<a
									onClick={(e) => { e.preventDefault(); this.toggleCollapse(); }}
									className={`collapse-link ${isOpened?"is-opened":"is-not-opened"}`}
								>
									<FormattedMessage {...details["groups.show"]}
										values={{
											groupCount: count,
										}}
									/>
								</a>
								<Collapse isOpened={isOpened}>
									<ul className="cart-item--groups">
										{groups.map((group) =>
											<li key={group.id}>
												{group.name}
											</li>
										)}
									</ul>
								</Collapse>
							</div> :
							null
					}
				</div>
				;
		}
		else {
			detailsFragment =
				<div>
					<p>
						{description}
					</p>
				</div>
				;
		}
		let name = plan.name;

		return (
			<ul className={classes.join(" ")}>
				<li className="cart-item--image">
					<Image
						src={media ? media.path : null}
						alt={media ? media.text_alt : null}
					/>
				</li>
				<li className="cart-item--main">
					<span className="cart-item--name">
						{name}
					</span>
					<span className="cart-item--organization">
						<OrganizationLink organization={organization} />
					</span>
					<span className="cart-item--price">
						<Money value={item.subscriber_price} />
						<span className="cart-item--taxes-label">
							{" "}(<FormattedMessage {...labels.withTaxesIfApplicable} />)
						</span>
					</span>
					<span className="cart-item--details">
						{detailsFragment}
					</span>
				{
				}
					{
						reservations && reservations.length > 0 ?
							<div className="cart-item--reservations">
								<div className="cart-reservations--title">
									<FormattedMessage {...labels["reservations.title"]} />
								</div>
								<ul>
									{reservations.map((reservation, key) =>
										<SessionReservation
											key={key}
											handleDeleteReservation={handleDeleteReservation}
											reservation={reservation}
										/>
									)}
								</ul>
							</div> : null
					}
				</li>
				<li className="cart-item--subscriber">
                    <FormattedMessage {...labels.subscriber}
                        className="cart-item--subscriber-label"
                    />
                    <span className="cart-item--subscriber-name-separator">
                        <FormattedMessage {...labels.colon} />
                    </span>
                    <span className="cart-item--subscriber-name">{item.subscriber.full_name}</span>
				</li>
				<li>
					<Button
						className="cart-item--remove"
						onClick={() => this.props.onDelete(item.id)}
						title={formatMessage(labels["remove"])}
					>
						<FormattedMessage {...labels["remove"]} />
					</Button>
				</li>
			</ul>
		);
	}
}

CartItem.propTypes = {
	item: PropTypes.object,
	onDelete: PropTypes.func.isRequired,
	handleDeleteReservation: PropTypes.func.isRequired,
};

CartItem.defaultProps = {
};

CartItem.contextTypes = {
	intl: PropTypes.object,
};

export default CartItem;
